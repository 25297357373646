define("discourse/plugins/discourse-asana-plugin/discourse/templates/connectors/category-boxes-before-boxes/community-stats", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.community_stats_enabled}}
    <div class="community-stats-wrapper">
      <div class="community-stats-container">
        <div class="community-stats-item ambassadors">
          <div class="count">{{this.site.ambassadors_count}}</div>
          <div class="title"><a
              href="https://forum.asana.com/pub/become-an-asana-ambassador"
            >{{i18n "community_stats.asana_ambassadors"}}</a></div>
        </div>
  
        <div class="community-stats-item events">
          <div class="count">{{this.site.community_events_count}}</div>
          <div class="title"><a href="https://events.asana.com">{{i18n
                "community_stats.community_events"
              }}</a></div>
        </div>
  
        <div class="community-stats-item members">
          <div class="count">{{this.site.forum_members_count}}</div>
          <div class="title">{{i18n "community_stats.forum_members"}}</div>
        </div>
  
        <div class="community-stats-item questions">
          <div class="count">{{this.site.resolved_questions_count}}</div>
          <div class="title">{{i18n "community_stats.questions_resolved"}}</div>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "v8edplUG",
    "block": "[[[41,[30,0,[\"siteSettings\",\"community_stats_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"community-stats-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"community-stats-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"community-stats-item ambassadors\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"count\"],[12],[1,[30,0,[\"site\",\"ambassadors_count\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[10,3],[14,6,\"https://forum.asana.com/pub/become-an-asana-ambassador\"],[12],[1,[28,[35,3],[\"community_stats.asana_ambassadors\"],null]],[13],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"community-stats-item events\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"count\"],[12],[1,[30,0,[\"site\",\"community_events_count\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[10,3],[14,6,\"https://events.asana.com\"],[12],[1,[28,[35,3],[\"community_stats.community_events\"],null]],[13],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"community-stats-item members\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"count\"],[12],[1,[30,0,[\"site\",\"forum_members_count\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,[28,[35,3],[\"community_stats.forum_members\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"community-stats-item questions\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"count\"],[12],[1,[30,0,[\"site\",\"resolved_questions_count\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,[28,[35,3],[\"community_stats.questions_resolved\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"a\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-asana-plugin/discourse/templates/connectors/category-boxes-before-boxes/community-stats.hbs",
    "isStrictMode": false
  });
});